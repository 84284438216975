import './App.css';
import Terminal from './Terminal';

function App() {
return (
  <div className="term-outer">
    <Terminal />
  </div>
);
}

export default App;